<template>
  <div class="note-list-holder">
    <h5 class="h5">Уведомления (4)</h5>
    <ul class="note-list">
      <NotificationItem></NotificationItem>
      <NotificationItem></NotificationItem>
      <NotificationItem></NotificationItem>
      <NotificationItem></NotificationItem>
    </ul>
  </div>
</template>

<script>
// import { eventBus } from "../../main";
import NotificationItem from "./NotificationItem.vue";

export default {
  name: "NotificationList",
  data: () => ({
    activeElement: 0,
  }),
  components: {
    NotificationItem,
  },
  props: {
    orderListByStatus: Array,
    isFlorist: Boolean,
    orderId: Number,
  },
  methods: {
    // clickActiveOrder(id) {
    //   console.log(id);
    //   this.activeElement = id;
    //   eventBus.$emit("clickActiveOrder", {
    //     orderId: id,
    //   });
    // },
  },
};
</script>

<style scoped>
.h5 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}
.note-list {
  list-style: none;
  margin: 0;
  padding: 0 0 20px;
  max-height: 100%;
  overflow-y: auto;
}
.note-list-holder {
  height: 100%;
}
</style>
