<template>
  <b-container fluid="xl">
    <div class="container-inner">
      <NoteBtn
        @click.native="toggleOpened"
        :class="{ opened: isOpened }"
        :isOpened="isOpened"
      />
      <div class="col bg col-dates">
        <DateList />
      </div>
      <div class="col">
        <OrderTabs />
      </div>
      <div class="col bg col-note" :class="{ opened: isOpened }">
        <template v-if="noteEmpty">
          <NotificationEmpty
            notificationEmptyText="Нет уведомлений"
          ></NotificationEmpty>
        </template>
        <template v-if="!noteEmpty">
          <NotificationList></NotificationList>
        </template>
        <Product />
      </div>
    </div>
  </b-container>
</template>

<script>
import DateList from "../components/Dates/DateList.vue";
import NotificationEmpty from "../components/Notifications/NotificationEmpty.vue";
import NotificationList from "../components/Notifications/NotificationList.vue";
import OrderTabs from "../components/Orders/OrderTabs.vue";
import NoteBtn from "../components/UI/NoteButton.vue";
import Product from "../components/Product/Product.vue";

export default {
  data: () => ({
    noteEmpty: false,
    isOpened: false,
  }),
  name: "Main",
  props: {},
  components: {
    DateList,
    NotificationEmpty,
    NotificationList,
    NoteBtn,
    OrderTabs,
    Product,
  },
  methods: {
    toggleOpened: function () {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>

<style>
.text-sm {
  font-size: 12px;
  line-height: 15px;
}
.bg {
  background: #f6f3f1;
}
.container-inner {
  position: relative;
}
.btn-custom {
  position: fixed;
  background: #f1f1f1;
  border: none;
  right: 0;
  top: 0;
  padding: 10px;
  width: 50px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.btn-custom.btn-secondary:active,
.btn-custom.btn-secondary:focus,
.btn-custom.btn-secondary:hover {
  background: #f1f1f1;
  border: none;
  box-shadow: none;
}
.col-note {
  position: relative;
}

@media (min-width: 991px) {
  .btn-custom {
    display: none;
  }
  .container-inner {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr;
  }
  .col {
    padding: 40px 20px;
  }
  #app .container-xl {
    max-width: 1500px;
  }
  .btn.btn-custom {
    display: none;
  }
}
@media (max-width: 990px) {
  .col-note {
    position: fixed;
    transform: translateX(100%);
    top: 53px;
    bottom: 0;
    left: 0;
    right: auto;
    transition: 0.3s linear;
    width: 100vw;
    z-index: 1;
    padding: 20px 12px;
  }
  .col-note.opened {
    transform: translateX(0);
    left: 0;
    transition: 0.3s linear;
  }
  .col-dates {
    padding-right: 50px;
  }
}
</style>
