<template>
  <b-tabs content-class="mt-2">
    <b-tab title="Активные(14)" active>
      <b-list-group class="order-list-new">
        <OrderList />
      </b-list-group>
    </b-tab>

    <b-tab title="Отмененные(1)">
      <b-list-group>
        <OrderList />
      </b-list-group>
    </b-tab>
  </b-tabs>
</template>

<script>
import OrderList from "../Orders/OrderList.vue";

export default {
  data: () => ({}),
  props: {},
  name: "OrderTabs",
  components: {
    OrderList,
  },
};
</script>

<style scoped>
.tabs {
  padding-top: 20px;
}
.list-group {
  max-height: 100%;
  overflow-y: auto;
}
@media (min-width: 991px) {
  .tabs {
    padding-top: 0;
  }
}
</style>
