<template>
  <div>
    <div class="time-line">
      <span>08:00 - 11:00</span>
    </div>
    <OrderItem>Order</OrderItem>
    <OrderItem>Order</OrderItem>
    <div class="time-line">
      <span>11:00 - 14:00</span>
    </div>
    <div class="time-line">
      <span>14:00 - 17:00</span>
    </div>
    <OrderItem>Order</OrderItem>
    <div class="time-line">
      <span>17:00 - 20:00</span>
    </div>
    <div class="time-line">
      <span>20:00 - 22:00</span>
    </div>
    <OrderItem>Order</OrderItem>
    <div class="time-line">
      <span>22:00 - 08:00</span>
    </div>
  </div>
</template>

<script>
import OrderItem from "./OrderItem.vue";

export default {
  name: "OrdersList",
  data: () => ({}),
  components: {
    OrderItem,
  },
  props: {},
  methods: {},
};
</script>

<style scoped>
.time-line {
  margin: 30px 0 0;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 12px 0;
}
</style>
