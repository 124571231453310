<template>
  <b-card class="order-item">
    <div class="order-item-top">
      <span class="order-item-num">
        <span class="order-num">Заказ № 322211</span>
        <span class="text-sm">14.10 21:00</span>
      </span>
      <span class="text-sm status">Отменен</span>
    </div>
    <span class="text-sm text-address">ул. Ломоносова 43, кв.23 </span>
    <div class="text-sm">Доставка до подъезда, домофон не работает.</div>
  </b-card>
</template>

<script>
export default {
  name: "OrderItem",
  data: () => ({}),
  props: {},
  components: {},
};
</script>

<style scoped>
.order-num {
  font-weight: 600;
  display: inline-flex;
  margin-right: 4px;
}
.order-item {
  background: #eeeeee;
  border: none;
  margin-bottom: 8px;
  border-radius: 0;
  cursor: pointer;
}
.order-item:hover {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.order-item-top {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-address {
  display: inline-flex;
  margin-bottom: 12px;
}
.status {
  color: #ff0000;
}
</style>
