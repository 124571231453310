<template>
  <div class="product-item">
    <div class="product-item-top">
      <span class="bold">Заказ № 322211</span>
      <span class="text-sm">14.10 21:00</span>
      <div class="product-name">ул. Ломоносова 43, кв.23</div>
      <button type="button" class="btn-close"></button>
    </div>
    <div class="product-item-middle">
      <div class="product-item-row">
        <span class="text-sm bold">Состав заказа:</span>
      </div>
      <div class="product-item-row">
        <div class="product-item-name">Букет 39 Троянд Спрей Мікс</div>
        <span class="text-sm">Состав заказа:<span>1 шт</span></span>
      </div>
      <div class="product-item-row">
        <div class="product-item-name">Букет 39 Троянд Спрей Мікс</div>
        <span class="text-sm">Состав заказа:<span>1 шт</span></span>
      </div>
      <div class="product-item-block">
        <span class="text-sm">Комментарий менеджера</span>
        <div class="product-info">Довезти в сохранности</div>
      </div>
      <div class="product-item-block">
        <span class="text-sm">Комментарий клиента</span>
        <div class="product-info">
          Доставка до подъезда, домофон не работает.
        </div>
      </div>
      <div class="product-item-info">
        <div class="product-info-row">
          <span class="text-sm bold">Заказчик:</span>
        </div>
        <div class="product-info-row">
          <span class="text-sm">ФИО </span>
          <div class="product-info">Довезти в сохранности</div>
        </div>
        <div class="product-info-row">
          <span class="text-sm">Телефон </span>
          <a href="" class="product-item-info">
            +38 073 665 53 53
            <i class="icon-phone"></i>
          </a>
        </div>
      </div>
      <div class="product-item-info">
        <div class="product-info-row">
          <span class="text-sm bold">Получатель: </span>
        </div>
        <div class="product-info-row">
          <span class="text-sm">ФИО </span>
          <div class="product-info">Довезти в сохранности</div>
        </div>
        <div class="product-info-row">
          <span class="text-sm">Телефон </span>
          <a href="" class="product-item-info">
            +38 073 665 53 53
            <i class="icon-phone"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="product-item-bottom">
      <b-button variant="light" class="btn-link">Отменить</b-button>
      <button type="button" class="btn-default">Заказ выполнен</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Product",
};
</script>

<style scoped>
.product-item {
  position: absolute;
  display: none;
  left: auto;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: #f6f3f1;
  padding: 40px 20px;
  max-height: 100%;
  overflow-y: auto;
  transform: translateX(100%);
}
.product-item.opened {
  display: block;
}
.product-name {
  font-size: 24px;
  line-height: 29px;
}
.btn-link {
  border: none;
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  margin-right: 30px;
}
.product-item-bottom {
  margin-top: auto;
}
.bold {
  font-weight: bold;
}
.product-item-top {
  position: relative;
  padding-right: 60px;
  margin-bottom: 40px;
}
.btn-close {
  position: absolute;
  right: 0;
  top: -20px;
  border-radius: 50%;
  background-color: #c4c4c4;
  padding: 20px;
}
.product-item-row {
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.product-item-block {
  padding: 12px 0;
}
.product-item-info {
  margin: 45px 0;
}
.product-info {
  margin-top: 4px;
}
.icon-phone {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-image: url("/src/assets/images/phone.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid red;
}
</style>
