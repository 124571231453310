<template>
  <li class="note-item">
    <div class="note-item-top">
      <span class="note-item-num">Заказ № 322211</span>
      <span class="note-text-sm">14:38</span>
    </div>
    <p class="note-text-sm">Изменен адрес доставки</p>
    <div class="note-info">Волонтерская 74, кв 45</div>
    <h5 class="note-product-name">Букет 39 Троянд Спрей Мікс</h5>
    <div class="note-text-sm"><span>Артикул: 2403</span><span>1 шт</span></div>
  </li>
</template>

<script>
export default {
  name: "NotificationItem",
  data: () => ({}),
};
</script>

<style scoped>
.note-item-num {
  font-weight: 600;
}
.note-item {
  background: #fff;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s linear;
  padding: 15px;
  border-radius: 5px;
  overflow: hidden;
}
.note-item:hover {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}
.note-item-top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.note-product-name {
  margin: 16px 0 8px 0;
  font-size: 18px;
  line-height: 22px;
}
p.note-text-sm {
  margin: 0 0 5px 0;
}
.note-text-sm {
  font-size: 12px;
  line-height: 15px;
}
.note-info {
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0;
}
</style>
