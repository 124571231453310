<template>
  <h5 class="h5">{{ notificationEmptyText }}</h5>
</template>

<script>
export default {
  data: () => ({}),
  name: "NotificationEmpty",
  props: {
    notificationEmptyText: String,
  },
};
</script>

<style scoped>
.h5 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}
</style>