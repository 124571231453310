<template>
  <div class="date-list-holder">
    <h3>Даты</h3>
    <ul class="date-list">
      <li
        class="date-item"
        v-for="(date, index) in dates"
        :key="index"
        :class="{ active: index === 0 }"
      >
        <span> {{ date.date }}</span>
        <span> {{ date.day }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    dates: [
      {
        date: "14.10",
        day: "Четверг",
      },
      {
        date: "15.10",
        day: "Пятница",
      },
      {
        date: "16.10",
        day: "Суббота",
      },
      {
        date: "17.10",
        day: "Воскресенье",
      },
      {
        date: "18.10",
        day: "Понедельник",
      },
      {
        date: "19.10",
        day: "Вторник",
      },
      {
        date: "20.10",
        day: "Среда",
      },
    ],
  }),
  name: "DateList",
  props: {},
};
</script>

<style scoped>
h3 {
  display: none;
}
.date-list {
  display: flex;
  font-size: 14px;
}
.date-list-holder {
  overflow-x: auto;
  background: #f1f1f1;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.date-item.active {
  border-bottom: 1px solid #222;
  font-weight: bold;
}
.date-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin-right: 10px;
}
.date-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 991px) {
  h3 {
    display: block;
    margin: 0 0 30px;
  }
  .date-list-holder {
    background: none;
    margin: 0;
    padding: 0;
  }
  .date-list {
    flex-direction: column;
  }
  .date-item {
    background: #fff;
    margin-bottom: 16px;
    font-size: 16px;
    padding: 6px 0;
    margin: 0 8px 0 0;
    padding: 20px;
    margin: 0 0 16px 0;
    flex-direction: row;
  }
  .date-item span {
    margin-right: 10px;
  }

  .date-item.active {
    border: none;
    font-weight: normal;
    background: #ddd;
  }
}
</style>