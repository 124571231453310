<template>
  <b-button class="btn-custom">
    <template v-if="!isOpened">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="15" fill="#C4C4C4" />
        <path
          d="M22.73 20.0275C22.2478 19.5977 21.8257 19.1049 21.475 18.5625C21.0921 17.8138 20.8626 16.9961 20.8 16.1575V13.6875C20.8033 12.3703 20.3255 11.0972 19.4564 10.1075C18.5872 9.11774 17.3866 8.47943 16.08 8.3125V7.6675C16.08 7.49047 16.0097 7.32069 15.8845 7.19551C15.7593 7.07033 15.5895 7 15.4125 7C15.2355 7 15.0657 7.07033 14.9405 7.19551C14.8153 7.32069 14.745 7.49047 14.745 7.6675V8.3225C13.4501 8.50146 12.264 9.14363 11.4063 10.1301C10.5485 11.1165 10.0774 12.3803 10.08 13.6875V16.1575C10.0174 16.9961 9.78789 17.8138 9.405 18.5625C9.06044 19.1037 8.6451 19.5964 8.17 20.0275C8.11667 20.0744 8.07392 20.132 8.04461 20.1967C8.0153 20.2613 8.00009 20.3315 8 20.4025V21.0825C8 21.2151 8.05268 21.3423 8.14645 21.4361C8.24021 21.5298 8.36739 21.5825 8.5 21.5825H22.4C22.5326 21.5825 22.6598 21.5298 22.7536 21.4361C22.8473 21.3423 22.9 21.2151 22.9 21.0825V20.4025C22.8999 20.3315 22.8847 20.2613 22.8554 20.1967C22.8261 20.132 22.7833 20.0744 22.73 20.0275ZM9.04 20.5825C9.5052 20.1331 9.9148 19.6295 10.26 19.0825C10.7423 18.1782 11.0237 17.1805 11.085 16.1575V13.6875C11.0652 13.1015 11.1635 12.5175 11.374 11.9703C11.5846 11.4231 11.9031 10.9239 12.3106 10.5024C12.7181 10.0808 13.2063 9.74558 13.7461 9.51664C14.2859 9.28769 14.8662 9.1697 15.4525 9.1697C16.0388 9.1697 16.6191 9.28769 17.1589 9.51664C17.6987 9.74558 18.1869 10.0808 18.5944 10.5024C19.0019 10.9239 19.3204 11.4231 19.531 11.9703C19.7415 12.5175 19.8398 13.1015 19.82 13.6875V16.1575C19.8813 17.1805 20.1627 18.1782 20.645 19.0825C20.9902 19.6295 21.3998 20.1331 21.865 20.5825H9.04Z"
          fill="black"
        />
        <path
          d="M15.475 23.2525C15.79 23.2452 16.0922 23.1268 16.3283 22.9182C16.5644 22.7096 16.7191 22.4242 16.765 22.1125H14.135C14.1822 22.4326 14.3442 22.7247 14.5907 22.9344C14.8372 23.1441 15.1514 23.2572 15.475 23.2525Z"
          fill="black"
        />
      </svg>
    </template>
    <template v-if="isOpened">
      <div class="icon-close">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L15 15" stroke="black" />
          <path d="M1 15L15 1" stroke="black" />
        </svg>
      </div>
    </template>
  </b-button>
</template>
<script>
export default {
  name: "NoteButton",
  props: {
    isOpened: Boolean,
  },
};
</script>
<style>
.icon-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>